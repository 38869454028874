import React, { useMemo, useState, memo } from 'react';
import { useQuery } from 'react-query';
import { getAaroProductHistory } from 'services/outside/aaro/aaroProducts';
import {
    Box,
    CircularProgress,
    Alert,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
} from '@mui/material';
import MDBox from 'components/MDBox';
import Icon from '@mui/material/Icon';
import HistoryTimeline from '../History';
import { formatPrice } from 'helpers/priceHelpers';
import MDTypography from 'components/MDTypography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SMProgress from 'components/SMComponents/SMProgress';

// Cell Renderers
const CellRenderers = {
    WorkOrderStatus: ({ value }) => (
        <Chip
            label={value ? 'Planlı' : 'Plansız'}
            color={value ? 'success' : 'default'}
            icon={<Icon>{value ? 'assignment_turned_in' : 'assignment_late'}</Icon>}
            variant="outlined"
            size="small"
        />
    ),

    HistoryButton: ({ historyCount, onClick }) => (
        <IconButton color="success" onClick={onClick} disabled={historyCount === 0}>
            <Icon>history</Icon>
        </IconButton>
    ),

    TextCell: ({ value }) => (
        <MDTypography variant="body2" textTransform="capitalize">
            {value}
        </MDTypography>
    ),

    PriceCell: ({ value }) => <MDTypography variant="body2">{formatPrice(value)}</MDTypography>,

    TotalPriceCell: ({ value }) => (
        <MDTypography variant="body2" sx={{ fontWeight: 'bold' }}>
            {formatPrice(value)}
        </MDTypography>
    ),

    DeletedCell: ({ value, isDeleted }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isDeleted && <Icon sx={{ color: 'error.main', fontSize: '1rem' }}>delete</Icon>}
            <MDTypography
                variant="body2"
                textTransform="capitalize"
                sx={{
                    textDecoration: isDeleted ? 'line-through' : 'none',
                    color: isDeleted ? 'text.disabled' : 'text.primary',
                }}
            >
                {value}
            </MDTypography>
        </Box>
    ),
};

// Grid Columns Configuration
const createColumns = (getHistoryCount, setSelectedItem) => [
    {
        field: 'actions',
        headerName: '',
        minWidth: 50,
        flex: 0.3,
        sortable: false,
        renderCell: (params) => (
            <CellRenderers.HistoryButton
                historyCount={getHistoryCount(params.row.id)}
                onClick={() => setSelectedItem(params.row)}
            />
        ),
    },
    {
        field: 'stockCode',
        headerName: 'Stok Kodu',
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <CellRenderers.DeletedCell value={params.value} isDeleted={params.row.isDeleted} />,
    },
    {
        field: 'stockName',
        headerName: 'Ürün Adı',
        minWidth: 200,
        flex: 2,
        renderCell: (params) => <CellRenderers.DeletedCell value={params.value} isDeleted={params.row.isDeleted} />,
    },

    {
        field: 'production',
        headerName: 'Üretim Oranı',
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <SMProgress value={params.value || 0} />,
    },
    {
        field: 'shipment',
        headerName: 'Sevkiyat Oranı',
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <SMProgress value={params.value || 0} />,
    },
    {
        field: 'quantity',
        headerName: 'Miktar',
        minWidth: 100,
        flex: 0.8,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => <CellRenderers.TextCell value={params.value} />,
    },
    {
        field: 'price',
        headerName: 'Birim Fiyat',
        minWidth: 130,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => <CellRenderers.PriceCell value={params.value} />,
    },
    {
        field: 'total',
        headerName: 'Toplam Tutar',
        minWidth: 130,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => <CellRenderers.TotalPriceCell value={params.value} />,
    },
    {
        field: 'hasWorkOrder',
        headerName: 'İş Emri',
        minWidth: 120,
        flex: 0.8,
        renderCell: (params) => <CellRenderers.WorkOrderStatus value={params.value} />,
    },
];

// Yeni bir DetailPanel komponenti oluşturalım
const DetailPanel = memo(({ row }) => {
    // Özel olarak gösterilmemesi gereken alanlar
    const excludedFields = ['id', 'isDeleted'];

    // Objedeki tüm key-value çiftlerini al ve tabloya dönüştür
    const details = Object.entries(row)
        .filter(([key]) => !excludedFields.includes(key))
        .map(([key, value]) => {
            // Boolean değerleri formatla
            if (typeof value === 'boolean') {
                value = value ? 'Evet' : 'Hayır';
            }
            // Sayısal değerleri formatla
            else if (typeof value === 'number') {
                if (key.toLowerCase().includes('price') || key.toLowerCase().includes('total')) {
                    value = formatPrice(value);
                } else if (key.toLowerCase().includes('oran')) {
                    value = `%${value}`;
                }
            }

            return {
                id: key,
                field: key,
                value: value?.toString() || '-',
            };
        });

    return (
        <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
            <DataGridPro
                rows={details}
                columns={[
                    {
                        field: 'field',
                        headerName: 'Alan',
                        flex: 1,
                        renderCell: (params) => (
                            <MDTypography variant="body2" fontWeight="medium">
                                {
                                    params.value
                                        .replace(/([A-Z])/g, ' $1') // CamelCase'i boşluklarla ayır
                                        .replace(/^./, (str) => str.toUpperCase()) // İlk harfi büyük yap
                                }
                            </MDTypography>
                        ),
                    },
                    {
                        field: 'value',
                        headerName: 'Değer',
                        flex: 2,
                        renderCell: (params) => <MDTypography variant="body2">{params.value}</MDTypography>,
                    },
                ]}
                hideFooter
                autoHeight
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableSelectionOnClick
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                        padding: '8px',
                    },
                    '& .MuiDataGrid-row': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: 'rgba(0, 0, 0, 0.02)',
                        },
                    },
                    border: 'none',
                    borderRadius: 1,
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    },
                }}
            />
        </Box>
    );
});

const DetailsPage = ({ queries, orderID }) => {
    const { orderItemsQuery } = queries;
    const [selectedItem, setSelectedItem] = useState(null);
    const { data, isLoading, error } = useQuery(
        ['aaroProductHistory', orderID],
        () => getAaroProductHistory({ docID: orderID }),
        {
            enabled: !!orderID,
            refetchOnWindowFocus: false,
        }
    );

    const parseDetails = (detailsStr) => {
        try {
            if (!detailsStr) return [];
            if (typeof detailsStr === 'string') {
                return JSON.parse(detailsStr);
            }
            return Array.isArray(detailsStr) ? detailsStr : [detailsStr];
        } catch (error) {
            console.error('Parse Error:', error);
            return [];
        }
    };

    const getHistoryCount = (stockId) => {
        if (!data?.data?.result) return 0;

        return data.data.result.reduce((count, hist) => {
            const details = parseDetails(hist.details);
            return count + details.filter((detail) => Number(detail.HareketID) === Number(stockId)).length;
        }, 0);
    };

    const gridData = useMemo(() => {
        if (!orderItemsQuery?.data || !data?.data?.result) return [];
        const currentItems = orderItemsQuery.data.map((item) => ({
            id: item.HareketID,
            stockCode: item.KartKodu,
            stockName: item.KartAdi,
            quantity: item.Miktar,
            price: item.BirimFiyatNet,
            total: item.Tutar,
            modifiedBy: item.DgsKodu,
            isDeleted: false,
            production: item.UretimOrani || 0,
            shipment: item.SevkiyatOrani || 0,
            hasWorkOrder: item.UrIsEmriPlanVarMi || false,
            Aciklama: item.Aciklama,
            Aciklama1: item.Aciklama1,
            Aciklama2: item.Aciklama2,
            Aciklama3: item.Aciklama3,
            Aciklama4: item.Aciklama4,
            Aciklama5: item.Aciklama5,
            Aciklama6: item.Aciklama6,
            Aciklama7: item.Aciklama7,
            Aciklama8: item.Aciklama8,
            Aciklama9: item.Aciklama9,
            Aciklama10: item.Aciklama10,
            Aciklama11: item.Aciklama11,
        }));

        // Geçmiş kayıtlardaki ürünleri topla
        const historicalItems = data.data.result.flatMap((hist) => {
            const details = parseDetails(hist.details);
            return details.map((detail) => ({
                id: detail.HareketID,
                stockCode: detail.StokKodu,
                stockName: detail.StokAdi,
                quantity: detail.Miktar,
                price: detail.BrmFiyatN,
                total: detail.Tutar,
                modifiedBy: detail.DgsKodu,
                Aciklama: detail.Aciklama,
                Aciklama1: detail.Aciklama1,
                Aciklama2: detail.Aciklama2,
                Aciklama3: detail.Aciklama3,
                Aciklama4: detail.Aciklama4,
                Aciklama5: detail.Aciklama5,
                Aciklama6: detail.Aciklama6,
                Aciklama7: detail.Aciklama7,
                Aciklama8: detail.Aciklama8,
                Aciklama9: detail.Aciklama9,
                Aciklama10: detail.Aciklama10,
                Aciklama11: detail.Aciklama11,
            }));
        });

        // Mevcut siparişte olmayan ama geçmişte olan ürünleri bul
        const deletedItems = historicalItems
            .filter((histItem) => !currentItems.some((currItem) => currItem.id === histItem.id))
            .reduce((unique, item) => {
                // Tekrar eden kayıtları filtrele
                const exists = unique.some((u) => u.id === item.id);
                if (!exists) {
                    return [...unique, { ...item, isDeleted: true }];
                }
                return unique;
            }, []);

        // Tüm kayıtları birleştir
        return [...currentItems, ...deletedItems];
    }, [orderItemsQuery.data, data?.data?.result]);

    // Stil için getRowClassName ekleyelim
    const getRowClassName = (params) => {
        if (params.row.isDeleted) {
            return 'deleted-row';
        }
        return '';
    };

    const prepareTimelineData = (selectedItem) => {
        if (!data?.data?.result || !selectedItem) return { currentItems: [], pastItems: [] };

        // Mevcut sipariş verisi
        const currentItems = [
            {
                id: selectedItem.id,
                stockCode: selectedItem.stockCode,
                stockName: selectedItem.stockName,
                quantity: selectedItem.quantity,
                price: selectedItem.price,
                total: selectedItem.total,
                date: new Date(), // Şu anki tarih
                modifiedBy: selectedItem.modifiedBy,
                Aciklama: selectedItem.Aciklama,
                Aciklama1: selectedItem.Aciklama1,
                Aciklama2: selectedItem.Aciklama2,
                Aciklama3: selectedItem.Aciklama3,
                Aciklama4: selectedItem.Aciklama4,
                Aciklama5: selectedItem.Aciklama5,
                Aciklama6: selectedItem.Aciklama6,
                Aciklama7: selectedItem.Aciklama7,
                Aciklama8: selectedItem.Aciklama8,
                Aciklama9: selectedItem.Aciklama9,
                Aciklama10: selectedItem.Aciklama10,
                Aciklama11: selectedItem.Aciklama11,
            },
        ];

        // Geçmiş veriler
        const pastItems = data.data.result
            .flatMap((hist) => {
                const details = parseDetails(hist.details);
                return details
                    .filter((detail) => Number(detail.HareketID) === Number(selectedItem.id))
                    .map((detail) => ({
                        id: detail.HareketID,
                        stockCode: detail.StokKodu,
                        stockName: detail.StokAdi,
                        quantity: detail.Miktar,
                        price: detail.BrmFiyatN,
                        total: detail.Tutar,
                        date: new Date(detail.DgsTar),
                        modifiedBy: detail.DgsKodu,
                        Aciklama: detail.Aciklama,
                        Aciklama1: detail.A1,
                        Aciklama2: detail.A2,
                        Aciklama3: detail.A3,
                        Aciklama4: detail.A4,
                        Aciklama5: detail.A5,
                        Aciklama6: detail.A6,
                        Aciklama7: detail.A7,
                        Aciklama8: detail.A8,
                        Aciklama9: detail.A9,
                        Aciklama10: detail.A10,
                        Aciklama11: detail.A11,
                    }));
            })
            .sort((a, b) => b.date - a.date); // Tarihe göre sırala

        return { currentItems, pastItems };
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={2}>
                <Alert severity="error">Hata oluştu: {error.message}</Alert>
            </Box>
        );
    }

    return (
        <MDBox>
            <DataGridPro
                rows={gridData}
                columns={createColumns(getHistoryCount, setSelectedItem)}
                autoHeight
                hideFooter
                getRowClassName={getRowClassName}
                getDetailPanelContent={({ row }) => <DetailPanel row={row} />}
                getDetailPanelHeight={() => 'auto'}
                sx={{
                    '& .MuiDataGrid-cell': {
                        padding: '8px',
                    },
                    '& .MuiTypography-root': {
                        fontWeight: 'normal',
                    },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: 1,
                    '& .deleted-row': {
                        backgroundColor: '#fff5f5',
                        '&:hover': {
                            backgroundColor: '#ffe9e9',
                        },
                        '& .MuiDataGrid-cell': {
                            opacity: 0.8,
                        },
                    },
                    '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: '#e3f2fd !important',
                        '&:hover': {
                            backgroundColor: '#e3f2fd !important',
                        },
                    },
                    '& .deleted-row.Mui-selected': {
                        backgroundColor: '#ffe9e9 !important',
                        '&:hover': {
                            backgroundColor: '#ffe9e9 !important',
                        },
                    },
                    '& .MuiDataGrid-detailPanel': {
                        backgroundColor: '#f5f5f5',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                }}
                initialState={{
                    pinnedColumns: {
                        left: ['actions'],
                    },
                }}
            />

            <Dialog
                open={!!selectedItem}
                onClose={() => setSelectedItem(null)}
                maxWidth={false}
                fullWidth={true}
                PaperProps={{
                    sx: {
                        width: '90vw',
                        height: '90vh',
                        maxWidth: 'none',
                        maxHeight: 'none',
                        m: 0,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <DialogTitle>{selectedItem?.stockName} için Geçmiş Kayıtlar</DialogTitle>
                <DialogContent
                    sx={{
                        p: 0,
                        flex: 1,
                        overflow: 'hidden',
                    }}
                >
                    {selectedItem && <HistoryTimeline {...prepareTimelineData(selectedItem)} />}
                </DialogContent>
            </Dialog>
        </MDBox>
    );
};

export default DetailsPage;
