import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import MDBox from 'components/MDBox';
import AaroLoadingScreen from 'components/Loading';
import MDTypography from 'components/MDTypography';

import { DataGridPro } from '@mui/x-data-grid-pro';
import Icon from '@mui/material/Icon';
import Chip from '@mui/material/Chip';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import { getOrders } from 'services/outside/aaro/dekont.js';

import useWindowDimensions from 'hooks/useWindowDimensions';
import SMProgress from 'components/SMComponents/SMProgress';

const calculateTime = (date) => {
    const currentDate = new Date();
    let timePassed = currentDate - new Date(date);
    const twentyFourHoursToMS = 86400000;
    return Math.ceil(timePassed / twentyFourHoursToMS);
};

const fetchDocuments = async (query) => {
    const response = await getOrders(query);
    return response?.data;
};

export default function AllOrders() {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [rowCountState, setRowCountState] = useState(0);

    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    const orderTypeID = 10013;
    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchDocuments', orderTypeID, page + 1, pageSize],
        () =>
            fetchDocuments({
                // TipID: orderTypeID,
                SirketID: 1,
                SiralamaKisiti: 'OlsTar:Desc',
                Durum: true,
                Sayfa: page + 1,
                SayfaSatirSayisi: 50,
            }),
        { refetchOnWindowFocus: false, staleTime: 60000 }
    );

    useEffect(() => {
        setRowCountState(data?.SayfalandirmaBilgisi?.ToplamSatirSayisi || 0);
    }, [data?.SayfalandirmaBilgisi?.ToplamSatirSayisi]);

    if (isLoading || isFetching) return <AaroLoadingScreen />;
    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const columns = [
        {
            field: 'olusmaTarihi',
            headerName: 'Gün',
            flex: 0.15,
            hide: width <= 600,
            renderCell: (params) => <ProjectButtons name={`${calculateTime(params.formattedValue)} gün`} />,
        },
        {
            field: 'type',
            headerName: 'Onay Durumu',
            flex: width <= 600 ? 0.6 : 0.3,
            renderCell: (params) => <SituationButtons OnayDurum={params.value} />,
        },
        {
            field: 'amount',
            headerName: 'Oluşturan',
            flex: 0.3,
            hide: width <= 600,
            renderCell: (params) => (
                <MDTypography
                    variant="body2"
                    textTransform="capitalize"
                    onClick={() => navigate(`../orders/${params.id}`)}
                >
                    {params.formattedValue}
                </MDTypography>
            ),
        },
        {
            field: 'name',
            headerName: 'Adı',
            flex: 0.7,
            renderCell: (params) => (
                <MDTypography
                    variant="body2"
                    textTransform="capitalize"
                    onClick={() => navigate(`../orders/${params.id}`)}
                >
                    {params.formattedValue}
                </MDTypography>
            ),
        },
        {
            field: 'aciklama',
            headerName: 'Açıklama',
            flex: 0.7,
            renderCell: (params) => (
                <MDTypography
                    variant="body2"
                    textTransform="capitalize"
                    onClick={() => navigate(`../orders/${params.id}`)}
                >
                    {params.formattedValue}
                </MDTypography>
            ),
        },
        {
            field: 'production',
            headerName: 'Üretim Oranı',
            flex: 0.6,
            renderCell: (params) => <SMProgress value={params.value} />,
        },
        {
            field: 'shipment',
            headerName: 'Sevkiyat Oranı',
            flex: 0.6,
            renderCell: (params) => <SMProgress value={params.value} />,
        },

        {
            field: 'price',
            headerName: 'Sipariş Tutarı',
            flex: 0.3,
            hide: width <= 600,
            renderCell: (params) => {
                const amount = parseFloat(params.value);
                const currencySymbol = params.value.replace(/[0-9.,]/g, '').trim();

                const formattedValue = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: 'TRY',
                })
                    .format(amount)
                    .replace('TRY', currencySymbol);

                return (
                    <MDTypography
                        variant="body2"
                        textTransform="capitalize"
                        onClick={() => navigate(`../orders/${params.value}`)}
                        sx={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                        {formattedValue}
                    </MDTypography>
                );
            },
        },
    ];

    const rowValues = data?.Model?.map((el) => ({
        id: el?.DekontID,
        key: el?.customerID,
        olusmaTarihi: el?.OlsTar,
        type: el?.OnayDurum,
        name: el?.KartAdi,
        aciklama: el?.Aciklama,
        production: el?.UretimOrani,
        shipment: el?.SevkiyatOrani,
        amount: el?.OlsAdi,
        price: el?.Tutar + '' + el?.DovizSembol,
    }));

    return (
        <DashboardLayout>
            <MDBox sx={{ height: '100vh' }} bgColor="white">
                <DataGridPro
                    columns={columns}
                    rows={rowValues}
                    rowCount={rowCountState}
                    loading={isLoading}
                    rowsPerPageOptions={[50]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    initialState={{
                        pinnedColumns: {
                            left: ['olusmaTarihi'],
                            right: ['projeDurumu'],
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            padding: '8px',
                        },
                        '& .MuiTypography-root': {
                            fontWeight: 'normal',
                        },
                    }}
                />
            </MDBox>
        </DashboardLayout>
    );
}

const ProjectButtons = memo(({ name }) => <Chip label={name} color="default" variant="outlined" />);

const SituationButtons = memo(({ OnayDurum }) => {
    const onayColor = (OnayDurum) => {
        switch (OnayDurum) {
            case 1:
                return { color: 'success', text: 'Onaylandı', icon: 'check_circle' };
            case 0:
                return { color: 'info', text: 'BEKLİYOR', icon: 'info' };
            case -1:
                return { color: 'error', text: 'RET!', icon: 'error' };
            default:
                return { color: 'default', text: 'Bilinmiyor', icon: 'help' };
        }
    };
    const { color, text, icon } = onayColor(OnayDurum);
    return <Chip label={text} color={color} icon={<Icon>{icon}</Icon>} variant="outlined" />;
});
